<template>
  <div>
    <AutoPopup />
    <b-row>
      <b-col>
        <!-- <div v-if="isAdmin" class="register-info p-3">
					<p class="font-weight-bold">
						Učitelia, vytvorte si online triedy a zapojte sa do SÚŤAŽE.
						<a href="https://drive.google.com/file/d/1Ka2J30IWcFyR6FI4tWtX2yIYDpzUVZMk/view?usp=sharing" target="_blank" class="link">
							Viac info
						</a>
					</p>
				</div> -->
        <div
          class="a-profile-subpage-title -groups w-background-orange-light mt-5"
        >
          <div>
            <h1 class="teacher-color">
              {{ $t("my-classes") }}
              <icon-information-svg
                v-if="isAdmin"
                @click.prevent.stop=""
                class="icon"
                v-b-popover.top.click.blur.hover.viewport="
                  'Návody a tipy, ako využiť triedu a zadať úlohu nájdete v sekcii Pomocník vo vašom profile.'
                "
              />
            </h1>
            <p class="w-color-black">{{ $t("list-of-my-classes") }}</p>
          </div>
          <div class="z-icon-wrapper -large w-background-white">
            <svg-group-icon class="teacher-color" />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="create-group">
          <InputSelect
            class="select-year"
            v-model="selectedAcademicYear"
            :options="academicYears"
            :optionLabel="yearOptionLabel"
            :placeholder="$t('academic-year')"
            :label="$t('academic-year')"
          />
          <b-button
            v-if="isAdmin && selectedYearActive"
            class="create-group-button"
            type="button"
            variant="primary"
            @click="$refs['create-group-modal'].showModal()"
            >+ {{ $t("add-class") }}</b-button
          >
        </div>
        <b-tabs>
          <b-col>
            <b-tab title="Moje Triedy" active>
              <GroupTable
                class="--clickable-rows awaiting-confirmation-groups mt-5"
                v-if="awaitingConfirmationGroups.length > 0 && !isAdmin"
                :tableItems="awaitingConfirmationGroups"
                :tableFields="studentUnconfirmedFields"
                :tableHeader="'d-none'"
                @groupToLeave="groupToRemoveOrLeave"
              />
              <GroupTable
                class="--clickable-rows"
                v-if="groups && groups.length"
                :tableItems="groups"
                :tableFields="isAdmin ? adminFields : studentActiveFields"
                @groupToLeave="groupToRemoveOrLeave"
                @groupToRemove="groupToRemoveOrLeave"
                @rowClicked="navigateToGroup"
              />
              <div
                v-else-if="groups && !groups.length"
                class="d-flex justify-content-center m-5"
              >
                <div class="m-5 text-center">
                  <div v-if="isAdmin && selectedYearActive">
                    <h5>{{ $t("no-created-classes-active-year-teacher") }}</h5>
                    <b-button
                      class="mt-3"
                      type="button"
                      pill
                      size="sm"
                      variant="primary mb-0"
                      @click="$refs['create-group-modal'].showModal()"
                      >+ {{ $t("add-class") }}</b-button
                    >
                  </div>
                  <h5 v-else-if="isAdmin">
                    {{ $t("no-created-classes-previous-year-teacher") }}
                  </h5>
                  <h5 v-else>
                    {{ $t("no-classes-student") }}
                    <br />
                    {{ $t("recomend-to-teacher") }}
                  </h5>
                </div>
              </div>
            </b-tab>
          </b-col>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row> </b-row>
    <GroupCreateModal
      ref="create-group-modal"
      :getGroups="getGroups"
      :academicYears="academicYears"
      @createGroup="createGroup"
    />
    <RemoveModal
      v-if="groups"
      ref="remove-group-modal"
      header-text="remove-group-confirm"
      @remove="removeGroupOrLeave"
    >
      <p>
        {{ isAdmin ? $t("remove-confirm") : $t("leave-confirm") }}
        <b class="fw-bold">{{ groupName }} {{ groupSubject }}</b
        >?
      </p>
    </RemoveModal>
    <GroupJoinModal
      v-if="!isAdmin"
      ref="join-group-modal"
      @refresh="_loadGroups"
    />
  </div>
</template>

<script>
import { pullAllWith, isEqual, get } from "lodash";
import { mapGetters } from "vuex";
import axios from "/utils/axios";
import * as Sentry from "@sentry/vue";
import AutoPopup from "/components/AutoPopup.vue";

const api = {
  getAcademicYears: () => {
    return axios.getAuth("academic-years", {
      params: {
        sort: ["end:desc"],
      },
    });
  },
  admin: {
    getGroups: (userId, yearId) => {
      return axios.getAuth("groups", {
        params: {
          sort: ["createdAt"],
          populate: ["members.user", "creator.user", "tasks.responses"],
          filters: {
            creator: {
              id: userId,
            },
            academicYear: {
              id: yearId,
            },
          },
        },
      });
    },
    createGroup: (group) => axios.postAuth("groups", { data: group }),
    removeGroup: (id) => axios.deleteAuth(`groups/${id}`),
    copyMembers: (oldGroupId, newGroupId) =>
      axios.postAuth("group-copy/members", { oldGroupId, newGroupId }),
  },
  member: {
    getGroupMemberships: (userId) => {
      return axios.getAuth("group-members", {
        params: {
          sort: ["createdAt"],
          populate: [
            "group.tasks.responses",
            "group.creator.user",
            "group.academicYear",
          ],
          filters: {
            user: {
              id: userId,
            },
          },
        },
      });
    },
    joinGroup: async (hash) => {
      return axios.postAuth("group-invite/join", { inviteHash: hash });
    },
    leaveGroup: async (memberId) => {
      return axios.postAuth("group-invite/leave", { memberId });
    },
  },
};

export default {
  layout: "profile",

  components: {
    AutoPopup,
    GroupTable: () => import("/components/Group/GroupTable.vue"),
    RemoveModal: () => import("/components/RemoveModal.vue"),
    GroupJoinModal: () => import("/components/Group/GroupJoinModal.vue"),
    GroupCreateModal: () => import("/components/Group/GroupCreateModal"),
    "svg-group-icon": () => import("/assets/icons/groups.svg?inline"),
    "icon-information-svg": () =>
      import("/assets/icons/information.svg?inline"),
    InputSelect: () => import("/components/InputSelect.vue"),
  },

  data() {
    return {
      adminFields: [
        { key: "name", label: "Trieda", class: "class" },
        { key: "subject", label: "Predmet", class: "subject" },
        {
          key: "members_count",
          label: "Počet Študentov",
          class: "members_count",
        },
        {
          key: "active_tasks",
          label: "Počet aktívnych zadaní",
          class: "active_task_count",
        },
        { key: "created_at", label: "Dátum vytvorenia", class: "created_at" },
        { key: "space", label: "" },
        { key: "watch_group", label: "" },
        { key: "remove", label: "", class: "cancel" },
      ],
      studentActiveFields: [
        { key: "name", label: "Trieda", class: "group" },
        { key: "subject", label: "Predmet", class: "subject" },
        {
          key: "active_tasks",
          label: "Počet aktívnych zadaní",
          class: "active_task_count",
        },
        { key: "watch_group", label: "", class: "d-flex justify-content-end" },
        { key: "remove", label: "", class: "cancel" },
      ],
      studentUnconfirmedFields: [
        { key: "name", label: "Predmet", class: "white" },
        { key: "second_name", label: "Trieda", class: "group white" },
        { key: "space", label: "" },
        { key: "waiting", label: "", class: "white" },
        { key: "remove", label: "", class: "cancel white" },
      ],
      awaitingConfirmationGroups: [],
      groups: null,
      groupName: "",
      groupSubject: "",
      academicYears: [],
      selectedAcademicYear: null,
    };
  },

  watch: {
    selectedAcademicYear: async function (newValue) {
      if (!newValue) {
        this.selectedAcademicYear = this.academicYears[0];
        return;
      }

      this.selectedAcademicYear = newValue;
      this._loadGroups();
    },
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    isAdmin() {
      return this.isLoggedIn && this.user.type === "teacher";
    },
    selectedYearActive() {
      return get(this.selectedAcademicYear, "attributes.active");
    },
  },

  async created() {
    const hash = this.$route.query.hash;
    if (hash && this.isLoggedIn) {
      if (this.isAdmin) {
        this.$router.replace({ query: null });
      } else {
        this._joinGroup(hash);
      }
    }

    await this._loadYears();
    await this._loadGroups();
  },

  methods: {
    yearOptionLabel(option) {
      return option.attributes.name;
    },

    async _loadYears() {
      const academicYears = await api.getAcademicYears();
      this.academicYears = academicYears.data;
      this.selectedAcademicYear = academicYears.data[0];
    },

    groupToRemoveOrLeave(group) {
      if (this.isAdmin) {
        this.groupName = group.attributes.name;
        this.groupSubject = group.attributes.subject;
      } else {
        this.groupName = group.attributes.group.data.attributes.name;
        this.groupSubject = group.attributes.group.data.attributes.subject;
      }

      this.$refs["remove-group-modal"].showModal(group);
    },

    async removeGroupOrLeave(itemToRemove) {
      this.$toast.clear();
      try {
        this.isAdmin
          ? await api.admin.removeGroup(itemToRemove.id)
          : await api.member.leaveGroup(itemToRemove.id);

        this.$toast.success(this.$t("Trieda bola úspešne odstránená"));

        this.$nextTick(async () => {
          await this._loadGroups();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },

    async getGroups(yearId) {
      const groups = await api.admin.getGroups(this.user.id, yearId);

      return groups.data;
    },

    async createGroup(group, oldGroup) {
      this.$toast.clear();
      try {
        const newGroup = await api.admin.createGroup({
          ...group,
          academicYear: {
            connect: [
              {
                id: this.academicYears[0].id,
              },
            ],
          },
          creator: {
            connect: [
              {
                id: this.user.id,
              },
            ],
          },
        });

        if (oldGroup) {
          await api.admin.copyMembers(oldGroup.id, newGroup.data.id);
        }

        this.$toast.success(this.$t("Trieda bola úspešne vytvorená"));
        this.$nextTick(async () => {
          await this._loadGroups();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },

    async navigateToGroup(item, index, event) {
      if (this.isAdmin) {
        this.$router.push(`/triedy/${item.id}`);
        return;
      }

      this.$router.push(`/triedy/${get(item, "attributes.group.data.id")}`);
    },

    async _loadGroups() {
      const groups = this.isAdmin
        ? await api.admin.getGroups(this.user.id, this.selectedAcademicYear.id)
        : await api.member.getGroupMemberships(this.user.id);

      this.groups = this.isAdmin
        ? groups.data
        : groups.data.filter(
            (group) =>
              group.attributes.group.data &&
              !group.attributes.deletedAt &&
              group.attributes.group.data.attributes.academicYear.data.id ===
                this.selectedAcademicYear.id
          );
      this.awaitingConfirmationGroups.length = 0;
      this.groups.forEach((group) => {
        if (!group.attributes.active && group.attributes.invited) {
          this.awaitingConfirmationGroups.push(group);
        }
      });
      pullAllWith(this.groups, this.awaitingConfirmationGroups, isEqual);
    },

    async _joinGroup(hash) {
      try {
        const joinResponse = await api.member.joinGroup(hash);

        this.$router.replace({ query: null });
        this.$refs["join-group-modal"].showModal(joinResponse.group);
      } catch (error) {
        Sentry.captureException(error);
        this.$nextTick(() => {
          this.$router.replace({ query: null });
        });
        this.$toast.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.awaiting-confirmation-groups {
  color: white !important;
  background-color: #0064ff;
  &::v-deep .cancel {
    padding-top: 12px;
  }
}

.teacher-color {
  color: #f2994a;
}

h1 {
  color: #2b2e4a;
}

.create-group {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.create-group-button {
  width: 10.4375rem;
  height: 2.5rem;
  padding: 0;
  font-size: small;
  font-weight: bold;
}

.select-year {
  height: 3.5rem;
}

.icon {
  width: 40px;
  height: 40px;
  outline: none;
  color: var(--a-color-primary);
}

.register-info {
  font-family: "Poppins";
  max-width: 467px;
  background: #ffffff;
  border: 1px solid #ff0000;
  border-radius: 4px;
  margin: 20px auto;
  display: flex;
  align-items: center;

  p {
    color: #ff0000;
    white-space: normal;
    margin: 0;

    a.link {
      font-weight: 700;
      color: inherit;
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 480px) {
    max-width: 20em;
  }
}
</style>
